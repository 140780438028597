import React from 'react'
import Helmet from 'react-helmet'
import Offerings from '../Offerings'
import Testimonials from '../Testimonials'
import PropTypes from 'prop-types'

const HomePageTemplate = (props) => {
  const { title, subtitle, heading, description, offerings, meta_title, meta_description, testimonials, heading1, description1 } = props

  return (
    <div>
      <Helmet>
        <title>{meta_title}</title>
        <meta name='description' content={meta_description} />
      </Helmet>
      <div className='hero has-background is-large'>
        <img class='hero-background' src='/img/colored pencils background.jpg' />
        <div class='hero-body'>
          <div class='container'>
            
            <h1 class='title is-1 is-spaced'>
              {title}
            </h1>
            <h2 class='subtitle is-4 is-spaced'>
              {subtitle}
            </h2>         
          </div>
        </div>
      </div>
      <section className='section section--gradient'>
        <div className='container'>

          <div className='section'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='content'>
                <div className='message is-info'>
                    <h3 className='has-text-weight-semibold is-size-3 columns is-centered message-header has-text-white has-text-centered'>{heading1}</h3>
                    {/* <p className='columns box mt-4 is-size-5 has-text-left'>{description1}</p> */}
                    <div className='box mt-4 is-size-5 has-text-left'>
                    We are now accepting applications for tutees and tutors for the 2024-2025 school year! Interested in becoming a tutor or tutee? 
                    Visit our <a class="has-text-weight-bold" href="/tutoring">Tutoring page</a> to find out more and submit your application. Only limited space is available, so apply now!
                    </div>
                  </div>
                  <div className='message is-info'>
                    <h3 className='has-text-weight-semibold is-size-3 columns is-centered message-header has-text-white has-text-centered'>
                      {heading}
                    </h3>
                    <p className='columns is-centered box mt-4 is-size-5 has-text-centered'>{description}</p>
                  </div>
                  
                  <Offerings gridItems={offerings.blurbs} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
HomePageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  testimonials: PropTypes.array,
  heading1: PropTypes.string,
  description1: PropTypes.string,

}

export default HomePageTemplate
